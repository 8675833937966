<template>
    <div id="industry">
        <div class="industry-banner">
            <img :src="require(`@/assets/img/industryPage${pageIndex}_1.png`)" alt="" />
        </div>
        <div class="industry-container">
            <div class="module-title">
                {{ industryData.module }}
            </div>
            <div class="module-brief">
                {{ industryData.brief }}
            </div>
            <div class="module-effect">
                {{ industryData.effect }}
            </div>
            <div class="effect-detail" v-if="industryData.effectDetail!=''">
                {{ industryData.effectDetail }}
            </div>
            <div class="module-achievement">
                <div class="achievement-left">
                    <div class="achievement-pic">
                        <img src="@/assets/img/industryPage1_s.png" alt="" />
                    </div>
                    <div class="achievement-pic" v-for="(v,i) in 2" :key="i">
                        <img :src="require(`@/assets/img/industryPage${pageIndex}_${i+2}.png`)" alt="" />
                    </div>
                </div>
                <div class="achievement-right">
                    <p v-for="(v,i) in industryData.achievement" :key="i"><span>·</span>{{ v }}</p>
                </div>
                <div class="logo-name">
                    YANGWANG Technology
                </div>
            </div>
        </div>
        <div class="serve" data-aos="fade-up"  data-aos-once='true'>
            <div class="serve-bg">
                <img src="@/assets/img/serve-bg.png" alt="">
            </div>
            <div class="serve-content">
                <div class="serve-l">
                    <img src="@/assets/img/logo.png" alt="" />
                </div>
                <div class="serve-r">
                    <h1>用科技服务大众 为客户量身定制</h1>
                    <p>ShenZhen YANGWANG Technology company</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AOS from "aos";
export default {
    name:'parameter',
    data(){
      return{
       industryList:[
        {
            module:'工业应用',
            brief:'由于工业相机的性能稳定可靠易于安装，相机结构紧凑结实不易损坏，连续工作时间长，可在较差的环境下使用，快门时间非常短，可以抓拍高速运动的物体，弥补了人力受环境因素的影响。广泛应用于如开发金属材料及树脂材料时，用来观察材料受到冲击时内部裂纹产生的方向、状态等，可用来分析材料被破坏时物质的结构，再如质量检测、工件测量等环节。',
            effect:'被广泛应用于工业领域',
            effectDetail:'机器视觉    工业监视和检测    材料检测    PCB和SMT检测    印刷和包装    纺织物检测    四轮定位    太阳板检测    机器人自动化    运动动态监测',
            achievement:[],
        },
        {
            module:'显微镜应用',
            brief:'生物医疗显微学。我司的机器视觉相机为医疗和生物科学提供色彩鲜明的图像。具有高精度和色彩还原度高的特点，保证高超的显微学性能，颜色逼真，检测和存储可信度高。如果你还在寻找功能强大而构造简单，效果显著而价格低廉的显微相机，那么仰望科技会是一个不错的选择。',
            effect:'显微镜成像 生命科学',
            effectDetail:'',
            achievement:['医疗保健','生物科技','荧光','金相'],
        },
        {
            module:'科学研究',
            brief:'工业相机应用于显微图像采集，并且可对观察物体大小进行测量，对观察图片进行拍照保存等操作。此外，还有皮肤检视.发根检视.纺织检视.生物观察.宝石鉴定.协助视障人士阅读.印刷检视工业检视，精密机械等众多应用。',
            effect:'生命科学领域进一步深入研究和探索',
            effectDetail:'',
            achievement:['生命科学','医疗护理','技术'],
        },
        {
            module:'教学研究',
            brief:'教学研究。即拍即看、操作简单、清晰度高、功能多等特点，用于再现生动实验情景，完整还原实验过程，完整还原实验过程，显微显现与直观反馈，综合信息搜集整理与统计，用以满足电子信息类课程实验教学要求，可以实现图像捕捉、显示以及相关的图像处理。',
            effect:'教学其他应用',
            effectDetail:'',
            achievement:['机器学习','模式识别','三维测量','图像分割','人工智能'],
        },
       ],
       pageIndex:1,
       industryData:{},
      }
    },
    watch: {
      '$i18n.locale'(newValue, oldValue) {
        this.industryData = this.$tm('industrysList')[this.pageIndex-1];
      }
    },
    mounted(){
        this.pageIndex = this.$route.path.slice(-1);
        console.log(this.$tm)
        this.industryData = this.$tm('industrysList')[this.pageIndex-1];
        AOS.init({
            offset: 200,   
            duration: 600,   
            easing: 'ease-in-sine',   
            delay: 100
        })
    },
    methods:{
    }
}
</script>
<style lang="scss">
.index .commonHeader .headercontain{
    background: #273443;
}
#industry{
    width: 100%;
    margin-top: 140px;
    .industry-banner{
        width: r(1620);
        height: r(700);
        margin: r(120) auto;
    }
    .industry-container{
        width: 1200px;
        margin: 0 auto;
        .module-title{
            font-size: 70px;
            font-weight: bold;
            color: #273443;
            line-height: 101px;
            text-indent: 5px;
        }
        .module-brief{
            font-size: 34px;
            line-height: 61px;
            color: #273443;
            opacity: .5;
            margin: 49px 0 120px;
        }
        .module-effect{
            font-size: 70px;
            font-weight: bold;
            color: #273443;
            line-height: 101px;
            text-indent: 5px;
            margin-bottom: 40px;
        }
        .effect-detail{
            font-size: 34px;
            line-height: 61px;
            color: #273443;
            opacity: .5;
            margin-bottom: 40px;
        }
        .module-achievement{
            width: 100%;
            @include display(center,between);
            margin-bottom: 141px;
            position: relative;
            .achievement-left{
                width: 863.07px;
                height: 370px;
                display: flex;
                .achievement-pic{
                    width: 387.07px;
                    height: 370px;
                    margin-left: -94.5px;
                }
                .achievement-pic:first-child{
                    width: 283px;
                    margin-left: 0;
                }
            }
            .achievement-right{
                p{
                    span{
                        font-size: 46px;
                        line-height: 46px;
                        padding: 0 20px;
                    }
                    text-align: right;
                    font-size: 34px;
                    line-height: 61px;
                    color: #273443;
                    opacity: .5;
                }
            }
            .logo-name{
                position: absolute;
                bottom: -18.1px;
                right: 0.86px;
                line-height: 18.05px;
                color: #273443;
                font-size: 18px;
                opacity: .5;
            }
        }
    }
}
</style>